.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.textarea {
  color: #dcddde !important;

  background: rgba(0, 0, 0, 0.1) !important;
  border: 1px solid rgba(0, 0, 0, 0.3) !important;

  outline: none !important;
  box-shadow: none !important;

  transition: border-color .2s ease-in-out;
  resize: none;
  margin: 15px 0 !important;
}
.MuiOutlinedInput-input,
.MuiFormLabel-root {
  color: white !important;
}

body {
  background: #2c2f33;
  font-family: 'Fira Sans', sans-serif;
  color: #ffffff;
  text-align: center;
}


.icon {
  background-color: #23272a;
  border-radius: 25%;
  box-shadow: 5px 5px 10px #2c2f33;
}
.avatar img {
  border-radius: 50%;
  box-shadow: 5px 5px 10px #2c2f33;
}
.avatar, label {
  text-align: justify;
}
.button {
  background-color: #7289da !important;
  width: 15em;
  color: white !important;
}

button {
  background-color: #7289da !important;
  width: 10em;
}
form button {
  float: right;
  color: white !important;
  width: 5em !important;
}

.background {
  margin: 25px !important;
  /*background-color: #23272a;*/
}

.banner {
  background-color: #23272a;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding: 25px;
  border-radius: 10px;
  margin-top: 15px;
}
.form {
  width: 50%;
}

h1 {
  width: 100%;
}
